@import 'variables';

@media screen and (min-width:768px) and (max-width:1023px) { // Tablet (portrait)
	
/* Home/News */

.news--layout .article.first .meta {
	width: calc(((100%/18) * 6) - 20px);
}
.news--layout .article.first .image,
.news--layout .article.first .no-image {
	width: calc(((100%/18) * 12) + 20px);
}

/* Work (detail) */

section.work--detail .text.introduction {
	width: 75%;
	margin-bottom: 30px;
}
section.work--detail .work-detail--layout .content .text {
	font-size: 14px;
}
section.work--detail .work-detail--layout .content .text p {
	width: 95% !important;
}
section.work--detail .work-detail--layout .content.margin-bottom--100 {
	margin-bottom: 50px;
}
section.work--detail .work-detail--layout .content.margin-bottom--150 {
	margin-bottom: 75px;
}
section.work--detail .work-detail--layout .content.margin-bottom--175 {
	margin-bottom: 100px;
}
section.work--detail .work-detail--layout .content.margin-bottom--250 {
	margin-bottom: 125px;
}

}