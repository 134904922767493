@import "theme.default";

.owl-carousel {

    .owl-nav {
        height: 0;

        button.owl-prev, button.owl-next {
            position: absolute;
            width: $carousel-control-width;
            top: 0;
            bottom: 0;
            opacity: $carousel-control-opacity;

            // Hover/focus state
            @include hover-focus {
                color: $carousel-control-color;
                text-decoration: none;
                outline: 0;
                opacity: .9;
            }

            span {
                display: inline-block;
                width: $carousel-control-icon-width;
                height: $carousel-control-icon-width;
                background: transparent no-repeat center center;
                background-size: 100% 100%;
            }
        }

        button.owl-prev {
            left: 0;
            background: linear-gradient(90deg, rgba($black, .25), rgba($black, .001));
            span {
                background-image: $carousel-control-prev-icon-bg;
            }
        }
        button.owl-next {
            right: 0;
            background: linear-gradient(270deg, rgba($black, .25), rgba($black, .001));
            span {
                background-image: $carousel-control-next-icon-bg;
            }
        }
    }

    .owl-dots {
        position: absolute;
        right: 0;
        bottom: 30px;
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        // Use the .carousel-control's width as margin so we don't overlay those
        margin-right: $carousel-control-width;
        margin-left: $carousel-control-width;

        button.owl-dot {

            position: relative;
            flex: 0 1 auto;
            width: $carousel-indicator-width;
            height: $carousel-indicator-height;
            margin-right: $carousel-indicator-spacer;
            margin-left: $carousel-indicator-spacer;
            text-indent: -999px;
            background-color: rgba($carousel-indicator-active-bg, .5);
            border-radius: 20px;

            // Use pseudo classes to increase the hit area by 10px on top and bottom.
            &::before {
                position: absolute;
                top: -10px;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 10px;
                content: "";
            }
            &::after {
                position: absolute;
                bottom: -10px;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 10px;
                content: "";
            }

            span {

            }

            &.active {

                background-color: $carousel-indicator-active-bg;

                span {}

            }
        }
    }
}