
/* Global */

body {
    font-family: $font-family-base;
    background-color: #ffffff;
    color: $body-color;
    font-size: $font-size-1;
    -webkit-font-smoothing: antialiased;
    position: relative;
    transition: background-color 600ms ease-in-out;
}
a, a:active, a:focus, a:hover {
    color: $link-color;
    text-decoration: none;
}
body.practice a, body.practice a:active, body.practice a:focus, body.practice a:hover {
    color: $light-grey;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.2em;
}
h2 {
    $font-size: $font-size-4;
}
.container {
    max-width: 1400px;
    padding: 0 30px;
}
.loading {
    cursor: wait !important;
}
img.lazy,
div.lazy {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    will-change: opacity;
    -webkit-backface-visibility: hidden;
}
img.lazy.loaded,
div.lazy.loaded {
    opacity: 1;
}
[data-ratio] {
    display: block;
    position: relative;
    width: 100%;
    background-color: #f7f7f7;
}
body.practice [data-ratio] {
    background-color: #404040;
}
[data-ratio] > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
section.context {
    transition: padding-top 600ms ease-in-out, opacity 600ms ease-in-out;
}
section.context.is--loading {
    opacity: 0;
}
body.home section.context,
body.news-detail section.context {
    padding-top: 170px;
}
body.home header.top.lg,
body.news-detail header.top.lg {
    height: 170px;
}
body.work section.context,
body.practice.process section.context {
    padding-top: 360px;
}
body.work header.top.lg,
body.practice.process header.top.lg {
    height: 303px;
}
body.practice.background section.context,
body.practice.awards section.context {
    padding-top: 223px;
}
body.practice.background header.top.lg,
body.practice.awards header.top.lg {
    height: 223px;
}
body.practice.people section.context {
    padding-top: 335px;
}
body.practice.people header.top.lg {
    height: 335px;
}
body.sub-menu--open.work-is--open header.top.lg {
    height: 287px !important;
}
body.sub-menu--open.work-is--open:not(.has--scrolled) section.context {
    padding-top: 287px !important;
}
body.sub-menu--open.practice-is--open header.top.lg {
    height: 360px !important;
}
body.sub-menu--open.practice-is--open:not(.has--scrolled) section.context {
    padding-top: 223px !important;
}
body.practice.people.sub-menu--open.practice-is--open header.top.lg {
    height: 335px !important;
}
body.practice.sub-menu--open.practice-is--open:not(.has--scrolled) section.context {
    padding-top: 303px !important;
}
body.practice.people.sub-menu--open.practice-is--open:not(.has--scrolled) section.context {
    padding-top: 335px !important;
}
body.contact section.context {
    padding-top: 223px;
}
body.has--scrolled header.top.lg {
    height: auto !important;
}

/* Header */

header.top.lg {
    background-color: #ffffff;
    //background-color: cyan;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    transition: max-height 600ms ease-in-out, height 600ms ease-in-out, background-color 600ms ease-in-out, opacity 600ms ease-in-out;
}
body.practice header.top.lg {
    background-color: $dark-grey;
    //background-color: cyan;
}
header.top.lg .inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 40px 0;
    padding-bottom: 40px;
}
header.top a.site-logo {
    display: inline-block;
    transform: translateY(-7px);
}
header.top a.site-logo img {
    height: 17px;
}
header.top a.site-logo svg {
    //height: auto;
    height: 38.13px;
    width: 100px;
    fill: $dark-grey;
}
header.top ul.menu,
header.top ul.menu li,
header.top ul.sub-menu,
header.top ul.sub-menu li {
    @include list-reset;
}
header.top.lg ul.menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    line-height: 14px;
}
header.top.lg ul.menu > li {
    font-size:  $font-size-2;
    display: inline-block;
    flex: 1;
}
header.top ul.sub-menu {
    transition: max-height 600ms ease-in-out, height 600ms ease-in-out, opacity 600ms ease-in-out;
}
header.top ul.sub-menu,
header.top ul.sub-menu.is--active.quick-hide {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}
header.top ul.sub-menu.is--active {
    overflow: visible;
    opacity: 1;
    max-height: 400px;
}
body.has--scrolled:not(.sub-menu--open) header.top.lg ul.sub-menu {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}
body.sub-menu--open.work-is--open header.top.lg ul.menu > li.is--active ul.sub-menu,
body.sub-menu--open.practice-is--open header.top.lg ul.menu > li.is--active ul.sub-menu {
    overflow: visible !important;
    opacity: 1 !important;
    max-height: 400px !important;
}
header.top.lg ul.sub-menu li {
    font-size: $font-size-1;
    line-height: 1.4em;
}
header.top.lg ul.sub-menu > li:first-of-type {
    margin-top: 25px;
}
header.top ul.sub-menu li a {
    display: inline-block;
    color: $mid-grey;
    position: relative;
}
header.top ul.sub-menu li.active > a,
header.top ul.sub-menu li a:hover {
    color: #000000;
}
body.practice header.top ul.sub-menu li.active > a,
body.practice header.top ul.sub-menu li a:hover {
    color: $white;
}
header.top ul.sub-menu li.active > a:before {
    content: '';
    position: absolute;
    left: -25px;
    background-image: url('../../img/symbol-arrow--active.svg');
    background-repeat: no-repeat;
    height: 5px;
    width: 18px;
    top: 5px;
}
body.practice header.top ul.sub-menu li.active > a:before {
    background-image: url('../../img/symbol-arrow--active--light.svg');
}
header.top ul.sub-menu > li > ul.sub-menu {
    position: relative;
    left: 100%;
    overflow: visible;
    opacity: 1;
    max-height: none;
    display: none;
}
header.top ul.sub-menu > li.active > ul.sub-menu {
    display: block;
}
header.top.lg ul.sub-menu > li > ul.sub-menu li:first-of-type {
    margin-top: -16px;
}
header.top ul.sub-menu > li > ul.sub-menu li.active > a:before {
    content: '';
    position: absolute;
    left: -25px;
    background-image: url('../../img/symbol-arrow--active.svg');
    background-repeat: no-repeat;
    height: 5px;
    width: 18px;
    top: 5px;
}
body.practice header.top ul.sub-menu > li > ul.sub-menu li.active > a:before {
    background-image: url('../../img/symbol-arrow--active--light.svg');
}
header.top.lg ul.social,
header.top.lg ul.social li {
    @include list-reset;
}
header.top.lg ul.social {
    line-height: 10px;
    text-align: right;
    transform: translateY(2px);
}
header.top.lg ul.social li {
    display: inline-block;
    font-size: $font-size-1;
    margin-right: 5px;
}
header.top.lg ul.social li:last-of-type {
    margin-right: 0;
}
header.top.lg ul.social li a {
    color: $link-color;

    .bg-dark & {
        color: $link-color-dark;
    }
}
header.top.lg ul.social li a:hover {
    color: #000000;
}
body.practice header.top.lg ul.social li a:hover {
    color: $light-grey;
}

//
// EU Logo
//
header.top.lg a.eu-logo {
    float: right;
    display: inline-block;
    transform: translateY(-9px);

    svg {
        height: 35px;
        width: 35px;

        path {

            fill: $dark-grey;

            body.practice & {
                fill: #fff;
            }
        }
    }
}


/* Home/News */

.news--layout .article {
    margin-bottom: 30px;
    float: none;
}
.news--layout .article.first .inner {
    position: relative;
}
.news--layout .article.first .meta {
    width: calc(((100%/10) * 4) - 18px);
    float: left;
}
.news--layout .article .meta .meta--content {
    margin: 15px 0 0 0;
    color: $body-color;
}
.news--layout .article.first .meta .meta--content {
    background-color: $dark-grey;
    color: #ffffff;
    padding: 15px;
    margin: 0;
    min-height: 300px;
}
.news--layout .article.double .meta .meta--content {
    float: left;
}
.news--layout .article.double .meta .meta--content.left {
    width: calc((100%/4) * 2);
}
.news--layout .article.double .meta .meta--content.right {
    width: calc((100%/4) * 2);
}
.news--layout .article .meta .meta--content a {
    color: #000000;
}
.news--layout .article.first .meta .meta--content * {
    color: $white;
}
.news--layout .article.first .meta .meta--content a {
    color: $light-grey;
}
.news--layout .article .meta .meta--content h3 {
    font-size: $font-size-3;
    margin: 0 0 15px 0;
    width: 75%;
}
.news--layout .article .meta .meta--content h5 {
    font-size: $font-size-1;
    margin: 0 0 15px 0;
    color: $body-color;
}
.news--layout .article .meta .meta--content p {
    font-size: $font-size-1;
    margin: 0;
}
.news--layout .article .meta .meta--content p.read-more {
    margin: 15px 0;
}
.news--layout .article .meta .meta--content .social ul,
.news--layout .article .meta .meta--content .social ul li {
    @include list-reset;
}
.news--layout .article .meta .meta--content .social ul li {
    display: inline-block;
    font-size: $font-size-1;
    margin-right: 5px;
}
.news--layout .article .meta .meta--content .social ul li:last-of-type {
    margin-right: 0;
}
.news--layout .article .image {
    display: block;
}
.news--layout .article.first .image,
.news--layout .article.first .no-image {
    width: calc(((100%/10) * 6) + 18px);
    position: absolute;
    right: 0;
    height: 100%;
}
.news--layout .article.first .image .bg {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.news--layout .article.first .image.video-holder {
    overflow: hidden;
}
.news--layout .article.first .image .video,
.news--detail .video-holder .video {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.news--layout {
    video {
        width: 100%;
    }
}
.news--detail .video-holder .video {

    @include media-breakpoint-up(md) {
        height: 400px;
    }

    @include media-breakpoint-up(lg) {
        height: 512px;
    }
}
.news--layout .article.first .no-image,
.news--layout .article.double .no-image {
    background-color: $lightest-grey;
}
.news--layout .article .no-image {
    display: block;
    position: relative;
    height: 300px;
}
.news--layout .article .no-image .inner {
    background-color: $lightest-grey;
    position: absolute;
    height: 100%;
    width: 100%;
}
.news--layout .article .no-image .inner {
    padding: 15px;
}
.news--layout .article .no-image h3 {
    font-size: $font-size-3;
    margin: 0;
    width: 75%;
}

/* Section: two columns */
.section--two-columns {
    h1 {
        margin-top: 0;
        font-size: 1.5em;
    }
}

/* News (detail) */

section.news--detail .no-image {
    background-color: $lightest-grey;
    height: 300px;
}
section.news--detail .text h1,
section.news--detail .text h2,
section.news--detail .text h3 {
    font-size: 1.5em;
    margin: 0 0 15px 0;
    width: 75%;
}
section.news--detail .text h5 {
    font-size: $font-size-1;
    margin: 0 0 15px 0;
    color: $body-color;
}
section.news--detail .text p {
    font-size: $font-size-1;
    color: $body-color;
}
section.news--detail .text p:first-of-type {
    margin-top: 0;
}
section.news--detail .text p:last-of-type {
    margin-bottom: 0;
}

section.news--detail {

    .news-detail--layout {
        margin-top: 60px;

        .content .text {
            font-size: $font-size-3;
            padding: 15px;
            min-height: 250px;
            background-color: $dark-grey;
            color: #ffffff;
        }

        .content .text a {
            color: #ffffff;
        }

        .content .text p {
            width: 75%;
        }

        .content.col-sm-6 .text p,
        .content.col-sm-5 .text p,
        .content.col-sm-4 .text p {
            width: 90%;
        }

        .content.col-sm-3 .text p {
            width: 100%;
        }
        .content .text p:first-of-type {
            margin-top: 0;
        }
        .content .text p:last-of-type {
            margin-bottom: 0;
        }
        .content.margin-bottom--100 {
            margin-bottom: 100px;
        }
        .content.margin-bottom--150 {
            margin-bottom: 150px;
        }
        .content.margin-bottom--175 {
            margin-bottom: 175px;
        }
        .content.margin-bottom--250 {
            margin-bottom: 250px;
        }
    }
}


/* Work (Overview - Index) */

section.work--overview h2.page-title {
    font-size: $font-size-1;
    color: $mid-grey;
    margin: 0 0 30px 0;
}
section.work--overview h2.page-title a {
    color: $mid-grey;
}
section.work--overview h2.page-title a.active,
section.work--overview h2.page-title a:hover {
    color: $dark-grey;
}
section.work--overview .project--overview.index {
    display: flex;
    flex-flow: row wrap;
}
section.work--overview .project--overview.index .project {
    width: calc(1370px/6);
    float: left;
    padding: 0 15px;
    margin-bottom: 15px;
}
section.work--overview .project--overview.index .project .image {
    display: block;
    height: 50px;
    width: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    margin-right: 15px;
    float: left;
}
section.work--overview .project--overview.index .project .meta {
    width: calc(100% - 65px);
    float: left;
}
section.work--overview .project--overview.index .project .meta h3 {
    font-size: $font-size-1;
    margin: 0 0 2px 0;
}
section.work--overview .project--overview.index .project .meta h5 {
    font-size: $font-size-1;
    color: $mid-grey;
    margin: 0;
}
section.work--overview .project--overview.index .project .meta h5.category {
    margin-top: 4px;
}
section.work--overview .project--overview.category {
    display: flex;
    flex-flow: row wrap;
}
section.work--overview .project--overview.category .project {
    margin-bottom: 30px;
}
section.work--overview .project--overview.category .project .meta {
    margin-top: 15px;
}
section.work--overview .project--overview.category .project .meta h3 {
    font-size: $font-size-3;
    margin: 0 0 10px 0;
    color: $body-color;
}
section.work--overview .project--overview.category .project .meta h3 a {
    color: $dark-grey;
}
section.work--overview .project--overview.category .project .meta h5 {
    font-size: $font-size-1;
    color: $mid-grey;
    margin: 0 0 10px 0;
}
section.work--overview .project--overview.category .project .meta p {
    margin: 0;
}
section.work--overview .project-list--overview .head {
    margin-bottom: 15px;
}
section.work--overview .project-list--overview .head h3 {
    font-size: $font-size-1;
    margin: 0;
    color: $mid-grey;
}
section.work--overview .project-list--overview .content {
    color: $mid-dark-grey;
    margin-bottom: 2px;
}
section.work--overview .project-list--overview .content:last-of-type {
    margin-bottom: 0;
}
section.work--overview .project-list--overview .content p {
    margin: 0;
}
section.work--overview .project-list--overview .content p.title {
    color: $dark-grey;
}
section.work--overview section[data-view] {
    display: none;
}
section.work--overview section[data-view].is--active {
    display: block;
}

/* Work (detail) */

section.work--detail .text p:first-of-type {
    margin-top: 0;
}
section.work--detail .text p:last-of-type {
    margin-bottom: 0;
}
section.work--detail .text.introduction h3 {
    font-size: $font-size-3;
    margin: 0 0 15px 0;
}
section.work--detail .text.introduction {
    font-size: $font-size-3;
}
section.work--detail .meta,
section.work--detail .meta a {
    color: $mid-grey;
}
section.work--detail .meta a {
    display: inline-block;
}
section.work--detail .meta a:hover {
    color: $dark-grey;
}
section.work--detail .meta strong {
    font-weight: normal;
    color: $dark-grey;
}
section.work--detail .work-detail--layout {
    margin-top: 60px;
}
section.work--detail .work-detail--layout .content .text {
    font-size: $font-size-3;
    padding: 15px;
    min-height: 250px;
    background-color: $dark-grey;
    color: #ffffff;
}
section.work--detail .work-detail--layout .content .text a {
    color: #ffffff;
}
section.work--detail .work-detail--layout .content .text p {
    width: 75%;
}
section.work--detail .work-detail--layout .content.col-sm-6 .text p,
section.work--detail .work-detail--layout .content.col-sm-5 .text p,
section.work--detail .work-detail--layout .content.col-sm-4 .text p {
    width: 90%;
}
section.work--detail .work-detail--layout .content.col-sm-3 .text p {
    width: 100%;
}
section.work--detail .work-detail--layout .content .text p:first-of-type {
    margin-top: 0;
}
section.work--detail .work-detail--layout .content .text p:last-of-type {
    margin-bottom: 0;
}
section.work--detail .work-detail--layout .content.margin-bottom--100 {
    margin-bottom: 100px;
}
section.work--detail .work-detail--layout .content.margin-bottom--150 {
    margin-bottom: 150px;
}
section.work--detail .work-detail--layout .content.margin-bottom--175 {
    margin-bottom: 175px;
}
section.work--detail .work-detail--layout .content.margin-bottom--250 {
    margin-bottom: 250px;
}
section.work--detail .work-detail--layout {
    .video-holder .video {
        width: 100%;
    }
}

/* Practice */

body.practice {
    background-color: $dark-grey;
    color: $mid-grey;
}
body.practice header.top a.site-logo svg {
    fill: #ffffff;
}

/* Practice (Background) + (Process) */

section.practice--background .text p:first-of-type,
section.practice--process .text p:first-of-type {
    margin-top: 0;
}
section.practice--background .text p:last-of-type,
section.practice--process .text p:last-of-type {
    margin-bottom: 0;
}
section.practice--background .text.introduction,
section.practice--process .text.introduction {
    font-size: $font-size-3;
    color: $light-grey;
    margin-bottom: 30px;
}
section.practice--background .text.sub-introduction,
section.practice--process .text.sub-introduction {
    font-size: $font-size-2;
    margin-bottom: 30px;
}
section.practice--background .meta-text .meta strong,
section.practice--process .meta-text .meta strong {
    font-weight: normal;
    color: $light-grey;
}
section.practice--background .meta-text .meta a,
section.practice--process .meta-text .meta a {
    color: $mid-grey;
}
section.practice--background .images .image,
section.practice--process .images .image {
    margin-bottom: 15px;
}
section.practice--background .images .image:last-of-type,
section.practice--process .images .image:last-of-type {
    margin-bottom: 0;
}

/* Practice (Press & Awards) */

.press-awards--layout .article {
    margin-bottom: 30px;
}
.press-awards--layout .article .meta .meta--content {
    margin: 15px 0 0 0;
    color: #000000;
}
.press-awards--layout .article .meta .meta--content a {
    color: $light-grey;
}
.press-awards--layout .article .meta .meta--content h3 {
    font-size: $font-size-3;
    margin: 0 0 15px 0;
    width: 75%;
    color: $light-grey;
}
.press-awards--layout .article .meta .meta--content h5 {
    font-size: $font-size-1;
    margin: 0 0 15px 0;
    color: $mid-grey;
}
.press-awards--layout .article .meta .meta--content .text p {
    font-size: $font-size-1;
    color: $mid-grey;
}
.press-awards--layout .article .meta .meta--content .text p:first-of-type {
    margin-top: 0;
}
.press-awards--layout .article .meta .meta--content .text p:last-of-type {
    margin-bottom: 0;
}
.press-awards--layout .article .meta .meta--content .social {
    margin-top: 15px;
}
.press-awards--layout .article .meta .meta--content .social ul,
.press-awards--layout .article .meta .meta--content .social ul li {
    @include list-reset;
}
.press-awards--layout .article .meta .meta--content .social ul li {
    display: inline-block;
    font-size: $font-size-1;
    margin-right: 5px;
}
.press-awards--layout .article .meta .meta--content .social ul li:last-of-type {
    margin-right: 0;
}
.press-awards--layout .article .image {
    display: block;
}
.press-awards--layout .article .no-image {
    display: block;
    position: relative;
    height: 250px;
}
.press-awards--layout .article .no-image .inner {
    background-color: #404040;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 15px;
}
.press-awards--layout .article .no-image h3 {
    font-size: $font-size-3;
    margin: 0;
    width: 75%;
    color: $light-grey;
}

/* Practice (People) */

.practice-people--layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}
.practice-people--layout .person {
    transition: top 300ms ease-in-out, left 300ms ease-in-out;
    margin-bottom: 30px;
}
.practice-people--layout .person .no-image {
    background-color: #404040;
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 75%;
}
.practice-people--layout .person .no-image .inner {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 15px;
}
.practice-people--layout .person .no-image h3 {
    font-size: $font-size-3;
    margin: 0;
    width: 75%;
    color: $light-grey;
}
.practice-people--layout .person .meta {
    margin-top: 15px;
}
.practice-people--layout .person .meta a {
    color: $light-grey;
}
.practice-people--layout .person .meta h3 {
    font-size: $font-size-3;
    margin: 0 0 10px 0;
    width: 75%;
    color: $light-grey;
}
.practice-people--layout .person .meta h5 {
    font-size: $font-size-1;
    margin: 0;
    color: $mid-grey;
}
.practice-people--layout .person .meta p {
    margin: 10px 0 0 0;
}
.practice-people--layout .person .meta .text p:first-of-type {
    margin-top: 0;
}
.practice-people--layout .person .meta .text p:last-of-type {
    margin-bottom: 0;
}
.practice-people--layout .person .meta .biography {
    display: none;
}
.practice-people--layout .person .meta .biography.is--active {
    display: block;
}
.practice-people--layout .person .meta .text {
    margin-top: 15px;
}


/* Contact */
section.contact {
    .text :first-child {
        margin-top: 0;
    }
}

/* Social share */
.social--share {

    @media only screen and (min-width: 1024px) {
        float: right;
    }
}
.social--share-item {
    display: block;
    .fab {
        display: inline-block;
        transform: translateY(20%);
        margin: 0  15px 15px 0;
        font-size: 30px;
    }
}

/* News carousel */
.carousel {
    margin-bottom: 15px;
    img {
        width: 100%;
        max-width: 100%;
        //object-fit: cover;
        //height: 300px;

        @include media-breakpoint-up(md) {
//            height: 400px;
        }

        @include media-breakpoint-up(lg) {
  //          height: 512px;
        }
    }
}

.owl-carousel .owl-item img {
    height:auto;
    width:100%;
    display: block;
}

.owl-carousel .item {
    margin:0;
}

.owl-carousel {
    .owl-dots button.owl-dot {
        width: 10px;
        height: 10px;
    }

    .owl-nav button.owl-prev span,
    .owl-nav button.owl-next span {
        width: 20px;
    }
}

/* Footer */

footer.main {
    position: relative;
    overflow: hidden;
    width: 100%;
}
footer.main section.map {
    margin-bottom: calc(-50vh - 100px);
    opacity: 0;
    transition: margin-bottom 600ms ease-in-out, opacity 600ms ease-in-out;
    will-change: margin-bottom;
    overflow: hidden;
    position: relative;
}
footer.main section.map.is--active {
    opacity: 1;
    margin-bottom: 0;
}
footer.main section.map .inner {
    margin-top: 70px;
}
footer.main section.map.is--active {
    margin-top: 0;
}
footer.main section.map .map-canvas {
    height: 50vh;
}
footer.main section.footer {
    background-color: #ffffff;
    //background-color: yellow;
    font-size: $font-size-1;
    color: $mid-grey;
    margin: 100px 0 0 0;
    padding: 60px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    transition: background-color 600ms ease-in-out;
}
body.practice footer.main section.footer {
    background-color: $dark-grey;
    //background-color: yellow;
}
footer.main section.footer h3 {
    font-size: $font-size-2;
    margin: 0;
    color: $dark-grey;
}
body.practice footer.main section.footer h3 {
    color: $light-grey;
}
footer.main section.footer .text p:first-of-type {
    margin-top: 0;
}
footer.main section.footer .text p:last-of-type {
    margin-bottom: 0;
}
footer.main section.footer .text p.first--reset {
    margin-top: 0;
}
footer.main section.footer .text strong {
    font-weight: normal;
    color: $dark-grey;
}
body.practice footer.main section.footer .text strong {
    color: $light-grey;
}
footer.main section.footer .text a {
    color: $dark-grey;
}
body.practice footer.main section.footer .text a {
    color: $light-grey;
}

.video-scrim {
    position:relative;
    display:block;
}
.scrim:after {
    content: "";
    transition: background-color 200ms ease-in-out;
}
.scrim:hover:after {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

body.bg-dark {
    background-color: $bg-dark;
    color: #8c8c8c;

    a {
        color: $link-color-dark;
    }

    header.top.lg {
        background-color: #323232;
    }

    header.top ul.sub-menu li.active>a,
    header.top ul.sub-menu li a:hover {
        color: #fff;
    }

    footer.main section.footer {
        background-color: #323232;
    }
}
