/* Global */

$white: #fff;
$lightest-grey: #f2f2f2;
$light-grey: #cccccc;
$mid-grey: #8c8c8c;
$mid-dark-grey: #656565;
$dark-grey: #323232;
$body-color: rgb(120, 120, 120);
$body-color: #555;
$headings-color: #555;
$link-color: #555;

// ** Dark Background
$bg-dark: #323232;
$link-color-dark: #ccc; // dark background

// ** Font Family
$font-family-sans-serif: AndSuisse-Light-WebS, sans-serif;
$headings-font-family: AndSuisse-Light-WebS, sans-serif;
$font-family-base: $font-family-sans-serif;

// ** Font sizes
//$base-font-size: 12px; // 16px; // 12px
//$font-size-1: 12px; // 15px; //12px;
//$font-size-2: 14px; // 16px; //14px
//$font-size-3: 16px; // 18px; //16px
//$font-size-4: 18px; //20px; //18px

$base-font-size: 15px; // 12px
$font-size-1: 15px; //12px;
$font-size-2: 16px; //14px
$font-size-3: 18px; //16px
$font-size-4: 20px; //18px

//$font-size-5: 20px;
//$font-size-6: 22px;
//$font-size-7: 24px;
//$font-size-8: 26px;
//$font-size-9: 28px;
//$font-size-10: 30px;

// homepage


/* Resets */

@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}
@mixin input-reset {
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-appearance: none;
}
@mixin focus-reset {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    outline: none;
}

// Carousel

//$carousel-control-color:            $white !default;
//$carousel-control-width:            15% !default;
$carousel-control-opacity:          .6;

$carousel-indicator-width:          20px;
$carousel-indicator-height:         20px;
//$carousel-indicator-spacer:         3px !default;
//$carousel-indicator-active-bg:      $white !default;

//$carousel-caption-width:            70% !default;
//$carousel-caption-color:            $white !default;

$carousel-control-icon-width:       30px;

//$carousel-control-prev-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
//$carousel-control-next-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

//$carousel-transition:               transform .6s ease !default; // Define transform transition first if using multiple transitons (e.g., `transform 2s ease, opacity .5s ease-out`)
