
/* Webfonts */

@font-face {
    font-family: 'Akkurat Pro Light';
    src: url('../../webfonts/lineto-akkurat-pro-light.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}

/* Suisse */

$font-path: '../../webfonts/andsuisse/webxl';

@font-face {
    font-family: AndSuisse-Light-WebXL;
    font-weight: 300;
    font-style: italic;
    src: url('#{$font-path}/AndSuisse-Light-WebXL.eot');
    src: url('#{$font-path}/AndSuisse-Light-WebXL.eot?#iefix') format("embedded-opentype"),
    url('#{$font-path}/AndSuisse-Light-WebXL.woff') format("woff"),
    url('#{$font-path}/AndSuisse-Light-WebXL.ttf') format("truetype"),
    url('#{$font-path}/AndSuisse-Light-WebXL.svg#0882291fef93bcd06d472e1ee8939c3e') format("svg");
}

$font-path: '../../webfonts/andsuisse/webs';

@font-face {
    font-family: AndSuisse-Light-WebS;
    font-weight: 300;
    font-style: italic;
    src: url('#{$font-path}/AndSuisse-Light-WebS.eot');
    src: url('#{$font-path}/AndSuisse-Light-WebS.eot?#iefix') format("embedded-opentype"),
    url('#{$font-path}/AndSuisse-Light-WebS.woff') format("woff"),
    url('#{$font-path}/AndSuisse-Light-WebS.ttf') format("truetype"),
    url('#{$font-path}/AndSuisse-Light-WebS.svg#0882291fef93bcd06d472e1ee8939c3e') format("svg");
}
//
//@font-face {
//    font-family: Euroma-Avenir;
//    font-weight: 300;
//    src: url('#{$font-path}/Avenir-Book.eot');
//    src: url('#{$font-path}/Avenir-Book.eot?#iefix') format("embedded-opentype"),
//    url('#{$font-path}/Avenir-Book.woff') format("woff"),
//    url('#{$font-path}/Avenir-Book.ttf') format("truetype"),
//    url('#{$font-path}/Avenir-Book.svg#0882291fef93bcd06d472e1ee8939c3e') format("svg");
//}
//
//@font-face {
//    font-family: Euroma-Avenir;
//    font-weight: 500;
//    src: url('#{$font-path}/Avenir-Medium.eot');
//    src: url('#{$font-path}/Avenir-Medium.eot?#iefix') format("embedded-opentype"),
//    url('#{$font-path}/Avenir-Medium.woff') format("woff"),
//    url('#{$font-path}/Avenir-Medium.ttf') format("truetype"),
//    url('#{$font-path}/Avenir-Medium.svg#0882291fef93bcd06d472e1ee8939c3e') format("svg");
//}
//
//@font-face {
//    font-family: Euroma-Avenir;
//    font-weight: 800;
//    src: url('#{$font-path}/Avenir-Black.eot');
//    src: url('#{$font-path}/Avenir-Black.eot?#iefix') format("embedded-opentype"),
//    url('#{$font-path}/Avenir-Black.woff') format("woff"),
//    url('#{$font-path}/Avenir-Black.ttf') format("truetype"),
//    url('#{$font-path}/Avenir-Black.svg#0882291fef93bcd06d472e1ee8939c3e') format("svg");
//}
