@import 'variables';

@media screen and (max-width:767px) { // Phone

/* Global */

section.context {
    padding-top: 0 !important;
}

/* Header */

header.top.sm {
	padding: 40px 0;
}
header.top.sm ul.sub-menu li {
	font-size: $font-size-1;
}
header.top.sm ul.sub-menu li:first-of-type {
	margin-top: 10px;
}
header.top.sm ul.sub-menu li:last-of-type {
	margin-bottom: 10px;
}
header.top ul.sub-menu > li > ul.sub-menu {
	left: 0;
}

/* Home/News */

.news--layout {
	height: auto !important;
}
.news--layout .article {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
}
.news--layout .article.first .meta {
	width: 100%;
	float: none;
}
.news--layout .article.first .meta .meta--content {
	min-height: inherit;
}
.news--layout .article.double .meta .meta--content {
	float: none;
}
.news--layout .article.double .meta .meta--content.left,
.news--layout .article.double .meta .meta--content.right {
	width: 100%;
}
.news--layout .article.first .image {
	width: 100%;
	position: relative;
	height: 200px;
}
.news--layout .article.first .no-image,
.news--layout .article .no-image {
	width: 100%;
	position: relative;
	height: auto;
}
.news--layout .article .no-image .inner {
	position: relative;
	min-height: 200px;
}

/* News (detail) */

section.news--detail .image,
section.news--detail .no-image {
	margin-bottom: 15px;
}

/* Work (Overview - Index) */

section.work--overview .project--overview.index .project {
	width: 100%;
    float: none;
}
section.work--overview .project-list--overview .head {
	display: none;
}

/* Work (detail) */

section.work--detail .text.introduction {
	width: 100%;
	margin-bottom: 30px;
}
section.work--detail .meta.second,
section.work--detail .meta.third {
	margin-top: 15px;
}
section.work--detail .work-detail--layout {
	height: auto !important;
}
section.work--detail .work-detail--layout .content {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	margin-bottom: 15px !important;
}
section.work--detail .work-detail--layout .content .text {
	font-size: $font-size-1;
	min-height: 200px;
}
section.work--detail .work-detail--layout .content .text p {
	width: 95% !important;
}

/* Practice (background) */

section.practice--background .meta-text .meta {
	margin-bottom: 15px;
}
section.practice--background .images {
	margin-top: 30px;
}

/* Practice (Press & Awards) */

.press-awards--layout {
	height: auto !important;
}
.press-awards--layout .article {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
}
.press-awards--layout .article .no-image {
	height: 200px;
}

/* Practice (People) */

.practice-people--layout .person .no-image h3 {
	font-size: $font-size-1;
	width: 100%;
}

/* Footer */

footer.main section.map {
	display: none;
}
footer.main section.footer {
	margin: 0;
}
footer.main section.footer h3 {
	margin: 0 0 15px 0;
}
footer.main section.footer h3.social {
	margin-top: 60px;
}
footer.main section.footer .text p.first--reset {
	margin-top: 1em;
}
footer.main section.footer .text.address p {
	margin: 0;
}
footer.main section.footer .text.social {
	margin-bottom: 0;
}

}
