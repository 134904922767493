html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden], template {
    display: none
}

a {
    background: transparent
}

a:active, a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b, strong {
    font-weight: bold
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button, select {
    text-transform: none
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled], html input[disabled] {
    cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: bold
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td, th {
    padding: 0
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size:  $font-size-1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
    font-family: $font-family-base;
    font-size: $font-size-1;
    line-height: 1.42857143;
    color: $body-color;
    background-color: #fff
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

//a {
//    color: #428bca;
//    text-decoration: none
//}
//
//a:hover, a:focus {
//    color: #2a6496;
//    text-decoration: underline
//}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    width: 100% \9
;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: inline-block;
    width: 100% \9
;
    max-width: 100%;
    height: auto
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16, .col-xs-17, .col-sm-17, .col-md-17, .col-lg-17, .col-xs-18, .col-sm-18, .col-md-18, .col-lg-18 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18 {
    float: left
}

.col-xs-18 {
    width: 100%
}

.col-xs-17 {
    width: 94.44444444%
}

.col-xs-16 {
    width: 88.88888889%
}

.col-xs-15 {
    width: 83.33333333%
}

.col-xs-14 {
    width: 77.77777778%
}

.col-xs-13 {
    width: 72.22222222%
}

.col-xs-12 {
    width: 66.66666667%
}

.col-xs-11 {
    width: 61.11111111%
}

.col-xs-10 {
    width: 55.55555556%
}

.col-xs-9 {
    width: 50%
}

.col-xs-8 {
    width: 44.44444444%
}

.col-xs-7 {
    width: 38.88888889%
}

.col-xs-6 {
    width: 33.33333333%
}

.col-xs-5 {
    width: 27.77777778%
}

.col-xs-4 {
    width: 22.22222222%
}

.col-xs-3 {
    width: 16.66666667%
}

.col-xs-2 {
    width: 11.11111111%
}

.col-xs-1 {
    width: 5.55555556%
}

.col-xs-pull-18 {
    right: 100%
}

.col-xs-pull-17 {
    right: 94.44444444%
}

.col-xs-pull-16 {
    right: 88.88888889%
}

.col-xs-pull-15 {
    right: 83.33333333%
}

.col-xs-pull-14 {
    right: 77.77777778%
}

.col-xs-pull-13 {
    right: 72.22222222%
}

.col-xs-pull-12 {
    right: 66.66666667%
}

.col-xs-pull-11 {
    right: 61.11111111%
}

.col-xs-pull-10 {
    right: 55.55555556%
}

.col-xs-pull-9 {
    right: 50%
}

.col-xs-pull-8 {
    right: 44.44444444%
}

.col-xs-pull-7 {
    right: 38.88888889%
}

.col-xs-pull-6 {
    right: 33.33333333%
}

.col-xs-pull-5 {
    right: 27.77777778%
}

.col-xs-pull-4 {
    right: 22.22222222%
}

.col-xs-pull-3 {
    right: 16.66666667%
}

.col-xs-pull-2 {
    right: 11.11111111%
}

.col-xs-pull-1 {
    right: 5.55555556%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-18 {
    left: 100%
}

.col-xs-push-17 {
    left: 94.44444444%
}

.col-xs-push-16 {
    left: 88.88888889%
}

.col-xs-push-15 {
    left: 83.33333333%
}

.col-xs-push-14 {
    left: 77.77777778%
}

.col-xs-push-13 {
    left: 72.22222222%
}

.col-xs-push-12 {
    left: 66.66666667%
}

.col-xs-push-11 {
    left: 61.11111111%
}

.col-xs-push-10 {
    left: 55.55555556%
}

.col-xs-push-9 {
    left: 50%
}

.col-xs-push-8 {
    left: 44.44444444%
}

.col-xs-push-7 {
    left: 38.88888889%
}

.col-xs-push-6 {
    left: 33.33333333%
}

.col-xs-push-5 {
    left: 27.77777778%
}

.col-xs-push-4 {
    left: 22.22222222%
}

.col-xs-push-3 {
    left: 16.66666667%
}

.col-xs-push-2 {
    left: 11.11111111%
}

.col-xs-push-1 {
    left: 5.55555556%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-18 {
    margin-left: 100%
}

.col-xs-offset-17 {
    margin-left: 94.44444444%
}

.col-xs-offset-16 {
    margin-left: 88.88888889%
}

.col-xs-offset-15 {
    margin-left: 83.33333333%
}

.col-xs-offset-14 {
    margin-left: 77.77777778%
}

.col-xs-offset-13 {
    margin-left: 72.22222222%
}

.col-xs-offset-12 {
    margin-left: 66.66666667%
}

.col-xs-offset-11 {
    margin-left: 61.11111111%
}

.col-xs-offset-10 {
    margin-left: 55.55555556%
}

.col-xs-offset-9 {
    margin-left: 50%
}

.col-xs-offset-8 {
    margin-left: 44.44444444%
}

.col-xs-offset-7 {
    margin-left: 38.88888889%
}

.col-xs-offset-6 {
    margin-left: 33.33333333%
}

.col-xs-offset-5 {
    margin-left: 27.77777778%
}

.col-xs-offset-4 {
    margin-left: 22.22222222%
}

.col-xs-offset-3 {
    margin-left: 16.66666667%
}

.col-xs-offset-2 {
    margin-left: 11.11111111%
}

.col-xs-offset-1 {
    margin-left: 5.55555556%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18 {
        float: left
    }
    .col-sm-18 {
        width: 100%
    }
    .col-sm-17 {
        width: 94.44444444%
    }
    .col-sm-16 {
        width: 88.88888889%
    }
    .col-sm-15 {
        width: 83.33333333%
    }
    .col-sm-14 {
        width: 77.77777778%
    }
    .col-sm-13 {
        width: 72.22222222%
    }
    .col-sm-12 {
        width: 66.66666667%
    }
    .col-sm-11 {
        width: 61.11111111%
    }
    .col-sm-10 {
        width: 55.55555556%
    }
    .col-sm-9 {
        width: 50%
    }
    .col-sm-8 {
        width: 44.44444444%
    }
    .col-sm-7 {
        width: 38.88888889%
    }
    .col-sm-6 {
        width: 33.33333333%
    }
    .col-sm-5 {
        width: 27.77777778%
    }
    .col-sm-4 {
        width: 22.22222222%
    }
    .col-sm-3 {
        width: 16.66666667%
    }
    .col-sm-2 {
        width: 11.11111111%
    }
    .col-sm-1 {
        width: 5.55555556%
    }
    .col-sm-pull-18 {
        right: 100%
    }
    .col-sm-pull-17 {
        right: 94.44444444%
    }
    .col-sm-pull-16 {
        right: 88.88888889%
    }
    .col-sm-pull-15 {
        right: 83.33333333%
    }
    .col-sm-pull-14 {
        right: 77.77777778%
    }
    .col-sm-pull-13 {
        right: 72.22222222%
    }
    .col-sm-pull-12 {
        right: 66.66666667%
    }
    .col-sm-pull-11 {
        right: 61.11111111%
    }
    .col-sm-pull-10 {
        right: 55.55555556%
    }
    .col-sm-pull-9 {
        right: 50%
    }
    .col-sm-pull-8 {
        right: 44.44444444%
    }
    .col-sm-pull-7 {
        right: 38.88888889%
    }
    .col-sm-pull-6 {
        right: 33.33333333%
    }
    .col-sm-pull-5 {
        right: 27.77777778%
    }
    .col-sm-pull-4 {
        right: 22.22222222%
    }
    .col-sm-pull-3 {
        right: 16.66666667%
    }
    .col-sm-pull-2 {
        right: 11.11111111%
    }
    .col-sm-pull-1 {
        right: 5.55555556%
    }
    .col-sm-pull-0 {
        right: auto
    }
    .col-sm-push-18 {
        left: 100%
    }
    .col-sm-push-17 {
        left: 94.44444444%
    }
    .col-sm-push-16 {
        left: 88.88888889%
    }
    .col-sm-push-15 {
        left: 83.33333333%
    }
    .col-sm-push-14 {
        left: 77.77777778%
    }
    .col-sm-push-13 {
        left: 72.22222222%
    }
    .col-sm-push-12 {
        left: 66.66666667%
    }
    .col-sm-push-11 {
        left: 61.11111111%
    }
    .col-sm-push-10 {
        left: 55.55555556%
    }
    .col-sm-push-9 {
        left: 50%
    }
    .col-sm-push-8 {
        left: 44.44444444%
    }
    .col-sm-push-7 {
        left: 38.88888889%
    }
    .col-sm-push-6 {
        left: 33.33333333%
    }
    .col-sm-push-5 {
        left: 27.77777778%
    }
    .col-sm-push-4 {
        left: 22.22222222%
    }
    .col-sm-push-3 {
        left: 16.66666667%
    }
    .col-sm-push-2 {
        left: 11.11111111%
    }
    .col-sm-push-1 {
        left: 5.55555556%
    }
    .col-sm-push-0 {
        left: auto
    }
    .col-sm-offset-18 {
        margin-left: 100%
    }
    .col-sm-offset-17 {
        margin-left: 94.44444444%
    }
    .col-sm-offset-16 {
        margin-left: 88.88888889%
    }
    .col-sm-offset-15 {
        margin-left: 83.33333333%
    }
    .col-sm-offset-14 {
        margin-left: 77.77777778%
    }
    .col-sm-offset-13 {
        margin-left: 72.22222222%
    }
    .col-sm-offset-12 {
        margin-left: 66.66666667%
    }
    .col-sm-offset-11 {
        margin-left: 61.11111111%
    }
    .col-sm-offset-10 {
        margin-left: 55.55555556%
    }
    .col-sm-offset-9 {
        margin-left: 50%
    }
    .col-sm-offset-8 {
        margin-left: 44.44444444%
    }
    .col-sm-offset-7 {
        margin-left: 38.88888889%
    }
    .col-sm-offset-6 {
        margin-left: 33.33333333%
    }
    .col-sm-offset-5 {
        margin-left: 27.77777778%
    }
    .col-sm-offset-4 {
        margin-left: 22.22222222%
    }
    .col-sm-offset-3 {
        margin-left: 16.66666667%
    }
    .col-sm-offset-2 {
        margin-left: 11.11111111%
    }
    .col-sm-offset-1 {
        margin-left: 5.55555556%
    }
    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 1024px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18 {
        float: left
    }
    .col-md-18 {
        width: 100%
    }
    .col-md-17 {
        width: 94.44444444%
    }
    .col-md-16 {
        width: 88.88888889%
    }
    .col-md-15 {
        width: 83.33333333%
    }
    .col-md-14 {
        width: 77.77777778%
    }
    .col-md-13 {
        width: 72.22222222%
    }
    .col-md-12 {
        width: 66.66666667%
    }
    .col-md-11 {
        width: 61.11111111%
    }
    .col-md-10 {
        width: 55.55555556%
    }
    .col-md-9 {
        width: 50%
    }
    .col-md-8 {
        width: 44.44444444%
    }
    .col-md-7 {
        width: 38.88888889%
    }
    .col-md-6 {
        width: 33.33333333%
    }
    .col-md-5 {
        width: 27.77777778%
    }
    .col-md-4 {
        width: 22.22222222%
    }
    .col-md-3 {
        width: 16.66666667%
    }
    .col-md-2 {
        width: 11.11111111%
    }
    .col-md-1 {
        width: 5.55555556%
    }
    .col-md-pull-18 {
        right: 100%
    }
    .col-md-pull-17 {
        right: 94.44444444%
    }
    .col-md-pull-16 {
        right: 88.88888889%
    }
    .col-md-pull-15 {
        right: 83.33333333%
    }
    .col-md-pull-14 {
        right: 77.77777778%
    }
    .col-md-pull-13 {
        right: 72.22222222%
    }
    .col-md-pull-12 {
        right: 66.66666667%
    }
    .col-md-pull-11 {
        right: 61.11111111%
    }
    .col-md-pull-10 {
        right: 55.55555556%
    }
    .col-md-pull-9 {
        right: 50%
    }
    .col-md-pull-8 {
        right: 44.44444444%
    }
    .col-md-pull-7 {
        right: 38.88888889%
    }
    .col-md-pull-6 {
        right: 33.33333333%
    }
    .col-md-pull-5 {
        right: 27.77777778%
    }
    .col-md-pull-4 {
        right: 22.22222222%
    }
    .col-md-pull-3 {
        right: 16.66666667%
    }
    .col-md-pull-2 {
        right: 11.11111111%
    }
    .col-md-pull-1 {
        right: 5.55555556%
    }
    .col-md-pull-0 {
        right: auto
    }
    .col-md-push-18 {
        left: 100%
    }
    .col-md-push-17 {
        left: 94.44444444%
    }
    .col-md-push-16 {
        left: 88.88888889%
    }
    .col-md-push-15 {
        left: 83.33333333%
    }
    .col-md-push-14 {
        left: 77.77777778%
    }
    .col-md-push-13 {
        left: 72.22222222%
    }
    .col-md-push-12 {
        left: 66.66666667%
    }
    .col-md-push-11 {
        left: 61.11111111%
    }
    .col-md-push-10 {
        left: 55.55555556%
    }
    .col-md-push-9 {
        left: 50%
    }
    .col-md-push-8 {
        left: 44.44444444%
    }
    .col-md-push-7 {
        left: 38.88888889%
    }
    .col-md-push-6 {
        left: 33.33333333%
    }
    .col-md-push-5 {
        left: 27.77777778%
    }
    .col-md-push-4 {
        left: 22.22222222%
    }
    .col-md-push-3 {
        left: 16.66666667%
    }
    .col-md-push-2 {
        left: 11.11111111%
    }
    .col-md-push-1 {
        left: 5.55555556%
    }
    .col-md-push-0 {
        left: auto
    }
    .col-md-offset-18 {
        margin-left: 100%
    }
    .col-md-offset-17 {
        margin-left: 94.44444444%
    }
    .col-md-offset-16 {
        margin-left: 88.88888889%
    }
    .col-md-offset-15 {
        margin-left: 83.33333333%
    }
    .col-md-offset-14 {
        margin-left: 77.77777778%
    }
    .col-md-offset-13 {
        margin-left: 72.22222222%
    }
    .col-md-offset-12 {
        margin-left: 66.66666667%
    }
    .col-md-offset-11 {
        margin-left: 61.11111111%
    }
    .col-md-offset-10 {
        margin-left: 55.55555556%
    }
    .col-md-offset-9 {
        margin-left: 50%
    }
    .col-md-offset-8 {
        margin-left: 44.44444444%
    }
    .col-md-offset-7 {
        margin-left: 38.88888889%
    }
    .col-md-offset-6 {
        margin-left: 33.33333333%
    }
    .col-md-offset-5 {
        margin-left: 27.77777778%
    }
    .col-md-offset-4 {
        margin-left: 22.22222222%
    }
    .col-md-offset-3 {
        margin-left: 16.66666667%
    }
    .col-md-offset-2 {
        margin-left: 11.11111111%
    }
    .col-md-offset-1 {
        margin-left: 5.55555556%
    }
    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 1201px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18 {
        float: left
    }
    .col-lg-18 {
        width: 100%
    }
    .col-lg-17 {
        width: 94.44444444%
    }
    .col-lg-16 {
        width: 88.88888889%
    }
    .col-lg-15 {
        width: 83.33333333%
    }
    .col-lg-14 {
        width: 77.77777778%
    }
    .col-lg-13 {
        width: 72.22222222%
    }
    .col-lg-12 {
        width: 66.66666667%
    }
    .col-lg-11 {
        width: 61.11111111%
    }
    .col-lg-10 {
        width: 55.55555556%
    }
    .col-lg-9 {
        width: 50%
    }
    .col-lg-8 {
        width: 44.44444444%
    }
    .col-lg-7 {
        width: 38.88888889%
    }
    .col-lg-6 {
        width: 33.33333333%
    }
    .col-lg-5 {
        width: 27.77777778%
    }
    .col-lg-4 {
        width: 22.22222222%
    }
    .col-lg-3 {
        width: 16.66666667%
    }
    .col-lg-2 {
        width: 11.11111111%
    }
    .col-lg-1 {
        width: 5.55555556%
    }
    .col-lg-pull-18 {
        right: 100%
    }
    .col-lg-pull-17 {
        right: 94.44444444%
    }
    .col-lg-pull-16 {
        right: 88.88888889%
    }
    .col-lg-pull-15 {
        right: 83.33333333%
    }
    .col-lg-pull-14 {
        right: 77.77777778%
    }
    .col-lg-pull-13 {
        right: 72.22222222%
    }
    .col-lg-pull-12 {
        right: 66.66666667%
    }
    .col-lg-pull-11 {
        right: 61.11111111%
    }
    .col-lg-pull-10 {
        right: 55.55555556%
    }
    .col-lg-pull-9 {
        right: 50%
    }
    .col-lg-pull-8 {
        right: 44.44444444%
    }
    .col-lg-pull-7 {
        right: 38.88888889%
    }
    .col-lg-pull-6 {
        right: 33.33333333%
    }
    .col-lg-pull-5 {
        right: 27.77777778%
    }
    .col-lg-pull-4 {
        right: 22.22222222%
    }
    .col-lg-pull-3 {
        right: 16.66666667%
    }
    .col-lg-pull-2 {
        right: 11.11111111%
    }
    .col-lg-pull-1 {
        right: 5.55555556%
    }
    .col-lg-pull-0 {
        right: auto
    }
    .col-lg-push-18 {
        left: 100%
    }
    .col-lg-push-17 {
        left: 94.44444444%
    }
    .col-lg-push-16 {
        left: 88.88888889%
    }
    .col-lg-push-15 {
        left: 83.33333333%
    }
    .col-lg-push-14 {
        left: 77.77777778%
    }
    .col-lg-push-13 {
        left: 72.22222222%
    }
    .col-lg-push-12 {
        left: 66.66666667%
    }
    .col-lg-push-11 {
        left: 61.11111111%
    }
    .col-lg-push-10 {
        left: 55.55555556%
    }
    .col-lg-push-9 {
        left: 50%
    }
    .col-lg-push-8 {
        left: 44.44444444%
    }
    .col-lg-push-7 {
        left: 38.88888889%
    }
    .col-lg-push-6 {
        left: 33.33333333%
    }
    .col-lg-push-5 {
        left: 27.77777778%
    }
    .col-lg-push-4 {
        left: 22.22222222%
    }
    .col-lg-push-3 {
        left: 16.66666667%
    }
    .col-lg-push-2 {
        left: 11.11111111%
    }
    .col-lg-push-1 {
        left: 5.55555556%
    }
    .col-lg-push-0 {
        left: auto
    }
    .col-lg-offset-18 {
        margin-left: 100%
    }
    .col-lg-offset-17 {
        margin-left: 94.44444444%
    }
    .col-lg-offset-16 {
        margin-left: 88.88888889%
    }
    .col-lg-offset-15 {
        margin-left: 83.33333333%
    }
    .col-lg-offset-14 {
        margin-left: 77.77777778%
    }
    .col-lg-offset-13 {
        margin-left: 72.22222222%
    }
    .col-lg-offset-12 {
        margin-left: 66.66666667%
    }
    .col-lg-offset-11 {
        margin-left: 61.11111111%
    }
    .col-lg-offset-10 {
        margin-left: 55.55555556%
    }
    .col-lg-offset-9 {
        margin-left: 50%
    }
    .col-lg-offset-8 {
        margin-left: 44.44444444%
    }
    .col-lg-offset-7 {
        margin-left: 38.88888889%
    }
    .col-lg-offset-6 {
        margin-left: 33.33333333%
    }
    .col-lg-offset-5 {
        margin-left: 27.77777778%
    }
    .col-lg-offset-4 {
        margin-left: 22.22222222%
    }
    .col-lg-offset-3 {
        margin-left: 16.66666667%
    }
    .col-lg-offset-2 {
        margin-left: 11.11111111%
    }
    .col-lg-offset-1 {
        margin-left: 5.55555556%
    }
    .col-lg-offset-0 {
        margin-left: 0
    }
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .row:before, .row:after {
    content: " ";
    display: table
}

.clearfix:after, .container:after, .row:after {
    clear: both
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
    display: none !important
}

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
    display: none !important
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important
    }
    table.visible-xs {
        display: table !important
    }
    tr.visible-xs {
        display: table-row !important
    }
    th.visible-xs, td.visible-xs {
        display: table-cell !important
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm {
        display: block !important
    }
    table.visible-sm {
        display: table !important
    }
    tr.visible-sm {
        display: table-row !important
    }
    th.visible-sm, td.visible-sm {
        display: table-cell !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-block {
        display: block !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-inline {
        display: inline !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .visible-md {
        display: block !important
    }
    table.visible-md {
        display: table !important
    }
    tr.visible-md {
        display: table-row !important
    }
    th.visible-md, td.visible-md {
        display: table-cell !important
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .visible-md-block {
        display: block !important
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .visible-md-inline {
        display: inline !important
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 1201px) {
    .visible-lg {
        display: block !important
    }
    table.visible-lg {
        display: table !important
    }
    tr.visible-lg {
        display: table-row !important
    }
    th.visible-lg, td.visible-lg {
        display: table-cell !important
    }
}

@media (min-width: 1201px) {
    .visible-lg-block {
        display: block !important
    }
}

@media (min-width: 1201px) {
    .visible-lg-inline {
        display: inline !important
    }
}

@media (min-width: 1201px) {
    .visible-lg-inline-block {
        display: inline-block !important
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .hidden-md {
        display: none !important
    }
}

@media (min-width: 1201px) {
    .hidden-lg {
        display: none !important
    }
}
